body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (min-width: 1224px) {
@charset "utf-8";

* {
  margin: 0;
  padding: 0;
}

fieldset, img ,span{
  border: 0;
  display: inline-block;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul, li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

abbr, acronym {
  border: 0;
}

a {
  cursor: pointer;
}

img {
  border: none;
}

body {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  font-family: "微软雅黑";
  background-color: #f5f5f5;
}


/*** padding ***/
.p-20{
  padding: 20px;
}

.pt-10{
  padding-top: 10px;
}
.pt-15{
  padding-top: 15px;
}
.pt-40{
  padding-top: 40px;
}
.pt-20{
   padding-top: 20px;
}
.pt-25{
  padding-top: 25px;
}
.pt-60{
  padding-top: 60px;
}
.pt-70{
   padding-top: 70px;
 }
.pt-90{
  padding-top: 90px;
}
.pr-10{
  padding-right: 10px;
}
.pr-30{
  padding-right: 30px;
}
.pl-10{
  padding-left: 10px;
}
.pl-15{
  padding-left: 15px;
}
.pl-20{
  padding-left: 20px;
}
.pr-15{
  padding-right: 15px;
}
.pr-20{
  padding-right: 20px;
}
.pl-30{
  padding-left: 30px;
}
.pl-25{
  padding-left: 25px;
}
.l-105{
  line-height: 105px;
}
.pt-5{
  padding-top: 5px;
}
.pt-30{
  padding-top: 30px;
}
.pt-38{
  padding-top: 38px;
}
.pt-50{
  padding-top: 50px;
}
.pt-100{
  padding-top: 100px;
}
.pb-5{
  padding-bottom: 5px;
}
.pb-10{
  padding-bottom: 10px;
}
.pb-20{
  padding-bottom: 20px;
}
.pb-30{
   padding-bottom: 30px;
}
.pb-40{
  padding-bottom: 40px;
}
.pb-50{
  padding-bottom: 50px;
}
.pb-100{
  padding-bottom: 100px;
}
.p-30{
  padding: 30px;
}
.p-10{
  padding: 10px;
}
/*** padding ***/
/*** margin ***/
.mar-20{
  margin: 20px;
}
.ml-18{
  margin-left: 18px;
}
.ml-20{
   margin-left: 20px;
 }
.ml-30{
  margin-left: 30px;
}
.ml-40{
  margin-left: 40px;
}
.ml-10{
  margin-left: 10px;
}
.mart-5{
  margin-top: 5px;
}
.mart-10{
  margin-top: 10px;
}
.mart-30{
  margin-top: 30px;
}
.mart-20{
  margin-top: 20px;
}
.mr-10{
  margin-right: 10px;
}
.mr-15{
  margin-right: 15px;
}
.mr-20{
  margin-right: 20px;
}
.mar-auto{
  margin: 0 auto;
}
.mar-b-10{
  margin-bottom: 10px;
}
.mar-b-15{
  margin-bottom: 15px;
}
.mar-b-20{
  margin-bottom: 20px;
}
.mar-b-45{
  margin-bottom: 45px;
}
/*** margin ***/
/*** border ***/
.bor-none{
  border: none
}
.bor-t{
  border-top: 1px solid #e5e5e5;
}
.bor-t-ffa729{
  border-top: 1px solid #ffa729;
}
.bor-r{
  border-right: 1px solid #e5e5e5;
}
.bor-b-20{
  border-bottom: 20px solid #f5f5f5;
}
.bor-t-20{
  border-top: 20px solid #f5f5f5;
}
.bor-radius-10{
  border-radius: 10px;
}
.bor-radius-20{
   border-radius: 20px;
 }
.bor-radius-30{
  border-radius: 30px;
}
.bor-radius-5{
  border-radius: 5px;
}
.bor-1px{
  border: 1px solid #a9a9a9;
}
.bor-1px-e7bcf1{
  border: 1px solid #E7ECF1;
}
.bor-l-1px{
  border-left: 1px solid #e5e5e5;
}
.bor-r-1px{
  border-right: 1px solid #e5e5e5;
}
.bor-b-none{
  border-bottom: none !important;
}
.bor-b-1px{
  border-bottom: 1px solid #ccc;
}
/*** border ***/

/*** width ***/
.w025{
  width: 25%;
}
.w03333{
  width: 33.33333333333%;
}
.w0100{
  width: 100%;
}
.w-60{
  width: 60px;
}
.w-70{
  width: 70px;
}
.w-100{
  width: 100px;
}
.w-120{
  width: 120px;
}
.w-148{
  width: 148px;
}
.w-162{
  width: 162px;
}
.w-180{
  width: 180px;
}
.w-200{
  width: 200px;
}
.w-240{
  width: 240px;
}
.w-250{
  width: 250px;
}
.w-300{
  width: 300px;
}
.w-400{
  width: 400px;
}
.w-420{
  width: 420px;
}
.w-470{
  width: 470px;
}
.w-500{
  width: 500px;
}
.w-540{
  width: 540px;
}
.w-550{
  width: 550px;
}
.w-410{
  width: 412px;
}
.w-480{
  width: 480px;
}
.w-570{
  width: 570px;
}
.w-630{
  width: 630px;
}
.w-690{
  width: 690px;
}
.w-720{
  width: 720px;
}
.w-730{
  width: 730px;
}
.w-750{
  width: 750px;
}
.w-38{
  width: 38px;
}
.w-22{
  width: 22px;
}
.w-34{
   width: 34px;
 }
.w-50{
  width: 50px;
}
.w-52{
  width: 52px;
}
/*** width ***/

/*** height ***/
.hl-32{
  height: 32px;
  line-height: 32px;
}
.hl-36{
  height: 36px;
  line-height: 36px;
}
.hl-38{
  height: 38px;
  line-height: 38px;
}
.hl-50{
  height: 50px;
  line-height: 50px;
}
.hl-80{
  height: 80px;
  line-height: 80px;
}
.hl-100{
  height: 100px;
  line-height: 100px;
}
.h-160{
  height: 160px;
}
.h-240{
  height: 240px;
}
.h-300{
  height: 300px;
}
.hl-60{
  height: 60px;
  line-height: 60px;
}
.h-22{
  height: 22px;
}
.h-34{
  height: 34px;
}
.h-50{
  height: 50px;
}
.h-52{
  height: 52px;
}
.hl-52{
  height: 52px;
  line-height: 52px;
}
.hl-70{
  height: 70px;
  line-height: 70px;
}
.height-100{
  height: 100%;
}
.hl-99{
  height: 99px;
  line-height: 99px;
}
.hl-98{
  height: 98px;
  line-height: 98px;
}
.hl-90{
  height: 90px;
  line-height: 90px;
}
.hl-82{
  height: 82px;
  line-height: 82px;
}
.hl-200{
  height: 200px;
  line-height: 200px;
}
.h-400{
  height: 400px;
}
/*** height ***/

/*** font ***/
.f-14{
  font-size: 14px;
}
.f-15{
  font-size: 15px;
}
.f-16{
  font-size: 16px;
}
.f-18{
  font-size: 18px;
}
.f-20{
  font-size: 20px;
}
.f-22{
  font-size: 22px;
}
.f-24{
  font-size: 24px;
}
.f-30{
  font-size: 30px;
}
.f-28{
  font-size: 28px;
}
.f-26{
  font-size: 26px;
}
.f-32{
  font-size: 32px;
}
.f-36{
  font-size: 36px;
}
.f-44{
  font-size: 44px;
}
.f-50{
  font-size: 50px;
}
.f-54{
  font-size: 54px;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.text-decoration{
  text-decoration: line-through;
}
.t-indent-60 {
  text-indent: 60px;
}
.f-bold{
  font-weight: bold;
}
.t-indent-20{
  text-indent: 20px;
}
/*** font ***/
/*** color ***/
.c-ffa729{
  color: #ffa729;
}
.c-ea5413{
  color: #EA5413;
}
.c-ff6c76{
  color: #ff6c76;
}
.c-ff668f{
  color: #ff668f;
}
.c-fe9e38{
  color: #fe9e38;
}
.c-3e6bb3{
  color: #3e6bb3;
}
.c-c0c0c0{
  color: #c0c0c0;
}
.c-ff789c{
  color: #ff789c;
}
.c-ff4c4c{
  color: #ff4c4c;
}
.c-6593ee{
  color: #6593EE;
}
.c-80bfff{
  color: #80bfff;
}
.c-757575{
  color: #757575;
}
.c-ccc{
  color: #ccc;
}
.c-333{
  color: #333333;
}
.c-3c3c3c{
  color: #3c3c3c;
}
.c-666{
  color: #666666;
}
.c-999{
  color: #999999;
}
.c-888{
  color: #888888;
}
.c-fff{
  color: #ffffff;
}
.c-fe8c25{
  color: #fe8c25;
}
/*** color ***/
/*** background-color ***/
.bg-f5f5f5{
  background-color: #f5f5f5;
}
.bg-666{
  background-color: #666666;
}
.bg-ffa729{
  background-color: #ffa729;
}
.bg-3856df{
  background-color: #3856df;
}
.bg-3e6bb3{
  background-color: #3E6BB3;
}
.bg-6593ee{
  background-color: #6593EE;
}
.bg-1fc397{
  background-color: #1fc397;
}
.bg-00c4c2{
  background-color: #00C4C2;
}
.bg-f1f2f7{
  background-color: #f1f2f7;
}
.bg-fe9e38{
  background-color: #fe9e38;
}
.bg-57c8f2{
  background-color: #57C8F2;
}
.bg-fa9533{
  background-color: #FA9533;
}
.bg-ff6c60{
  background-color: #FF6C60;
}
.bg-fff{
  background-color: #ffffff;
}
.bg-f8f8f8{
  background-color: #f8f8f8;
}
.bg-36404C{
  background-color: #36404C
}
.RegBox .bg-c-ffa729{
  background-color: #ffa729;
  border: 2px solid #ffa729;
  color: #ffffff;
}
.bg-ff789c{
  background-color: #ff789c;
}
/*** background-color ***/
/**border**/
.bor-fa729{
  border: 1px solid #ffa729;
}
.bor-fa729-2{
  border: 2px solid #ffa729;
}
.bor-1px-3e6bb3{
  border: 1px solid #3e6bb3;
}
.bor-b-2-EA5413{
  border-bottom: 2px solid #EA5413;;
}
.bor-t-dashed{
  border-top: 2px dashed #e5e5e5;
}
.bor-l-5px-00C4C2{
  border-left: 5px solid #00c4c2;
}
/**border**/
/*** flex ***/
.space-between{
  display: flex;
  justify-content: space-between;
}
.space-around{
  display: flex;
  justify-content: space-around;
}
.justify-center{
  display: flex;
  justify-content: center;
}
.flex-start{
  display: flex;
  justify-content: flex-start;
}
.flex-end{
  display: flex;
  justify-content: flex-end;
}
.flex{
  display: flex;
}
.flex-grow{
  flex-grow: 1;
}
.flex-wrap{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.display_none{
  display: none;
}
.display_inline_block{
  display: inline-block;
}

/*** flex ***/
/*** position ***/
.pos-center{
  position: absolute;
  top: 300px;
  left: 60px;
}
.pos-center-100{
  position: absolute;
  top: 150px;
  left: 60px;
}
.pos-center-80{
  position: absolute;
  top: 80px;
  left: 30px;
}
.pos-center-cancel{
  position: absolute;
  top: 400px;
  left: 95px;
}
.pos-bottom{
  position: fixed;
  left: 0;
  bottom: 0;
}
.pos-relative{
  position: relative;
}
.pos-repeat{
  position: absolute;
  left: 0;
  top: 0;
}
.pos-fixed{
  position: fixed;
  top: 0;
  left: 0;
}
/*** position ***/
/*** display ***/
.display-inline-block{
  display: inline-block;
}
/*** z-index ****/

.z-index{
  z-index: 109;
}
.z-index-99{
  z-index: 99;
}

}
img{
  display: block;
}
.w-1200{
  width: 1200px;
}
/******** 首页 *********/
.pos-sticky{
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}
.top-head{
  width: 100%;
}
.bor-t-3px{
  height: 77px;
  padding: 0 10px;
  line-height: 74px;
  display: inline-block;
  border-top: 3px solid #ffffff;
}
.bor-t-3px-ea5413{
  height: 77px;
  line-height: 74px;
  display: inline-block;
  border-top: 3px solid #ea5413;
}
.page-header{
  width: 1200px;
  margin: 0 auto;
}
.menuList li{
  width: 140px;
  text-align: center;
}
.tomove{
  width: 100%;
  overflow: hidden;
  position: relative;
}
.tomove-list{
  position: relative;
  height: auto;
}
.tomove-list li img{
  display: block;
}
.transition{
  -webkit-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s
}
.slippage{
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.icon{
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
}
.icon button{
  width: 100px;
  height: 50px;
  border: none;
  opacity: 0.3;
  margin-top: 250px;
  background-color: rgba(0,0,0,0);
}
button{
  outline: none;
}
.icon button:hover{
  opacity: 1;
}
.icon_back button{
  padding-left: 50px;
}
.icon_next button{
  padding-right: 50px;
}
.icon_back{
  left: 0
}
.icon_next{
  right: 0
}
.title{
  width: 100%;
  text-align: center;
  position: absolute;
  top: 210px;
  -webkit-animation: title 1s linear;
          animation: title 1s linear;
}
.dec{
  width: 100%;
  text-align: center;
  position: absolute;
  top: 290px;
  -webkit-animation: dec 1s linear;
          animation: dec 1s linear;
}
.dec p{
  width: 700px;
  margin: 0 auto;
}
@-webkit-keyframes title {
  from {top: 600px;}
  to { top: 210px;}
}
@keyframes title {
  from {top: 600px;}
  to { top: 210px;}
}
@-webkit-keyframes dec {
  from {top: 0;}
  to { top: 290px;}
}
@keyframes dec {
  from {top: 0;}
  to { top: 290px;}
}
.bigTitle{
  position: absolute;
  top: 50px;
  -webkit-animation: bigTitle 1s linear;
          animation: bigTitle 1s linear;
}
@-webkit-keyframes bigTitle {
  from {top: 170px;}
  to { top: 50px;}
}
@keyframes bigTitle {
  from {top: 170px;}
  to { top: 50px;}
}
.subTitle{
  position: absolute;
  top: 90px;
  -webkit-animation: subTitle 1s linear;
          animation: subTitle 1s linear;
}
@-webkit-keyframes subTitle {
  from {top: 0;}
  to { top: 90px;}
}
@keyframes subTitle {
  from {top: 0;}
  to { top: 90px;}
}
.more{
  position: absolute;
  width: 80px;
  height: 30px;
  line-height: 30px;
  top: 120px;
  right: 0;
  -webkit-animation: more 1s linear;
          animation: more 1s linear;
}
.more:hover{
  color: #EA5413;
  border: 1px solid #EA5413;
}

@-webkit-keyframes more {
  from { right: -200px; }
  to { right: 0;}
}

@keyframes more {
  from { right: -200px; }
  to { right: 0;}
}

.develop{
  width: 280px;
  height: 276px;
  padding: 0 40px;
  position: relative;
  box-shadow: 0 5px 30px rgba(2,31,65,0.1);
  border-radius:4px;
}
.develop img{
  position: absolute;
  top: 30px;
  left: 150px;
  -webkit-animation: developImg 1s linear;
          animation: developImg 1s linear;
}
.develop p{
  width: 280px
}

@-webkit-keyframes developImg {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}

@keyframes developImg {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}
.develop .p1{
  position: absolute;
  top: 120px;
  -webkit-animation: developP1 1s linear;
          animation: developP1 1s linear;
}

@-webkit-keyframes developP1 {
  from { top: 160px }
  to { top: 120px; }
}

@keyframes developP1 {
  from { top: 160px }
  to { top: 120px; }
}
.develop .p2{
  position: absolute;
  top: 170px;
  -webkit-animation: developP2 1s linear;
          animation: developP2 1s linear;
}

@-webkit-keyframes developP2 {
  from { top: 220px }
  to { top: 170px; }
}

@keyframes developP2 {
  from { top: 220px }
  to { top: 170px; }
}
.down_title{
  position: absolute;
  top: 140px;
}
.down_top_text{
  width: 100%;
  position: absolute;
  top: 40px;
}
.down_top_icon{
  width: 100%;
  position: absolute;
  bottom: 30px;
}
.downLi{
  width: 240px;
}
.downLi img{
  margin: 0 auto;
}
.about{
  margin-top: 115px;
  width: 148px;
  height: 36px;
  border: 1px solid #ddd;
  color: #333;
  font-size: 14px;
  background-color: rgba(0,0,0,0);
}
.about:hover{
  border: 1px solid #EA5413;
  color: #EA5413;
}

.posCut{
  width:1200px;
  height: 90px;
  background:rgba(255,255,255,1);
  box-shadow:0 5px 15px rgba(0,0,0,0.15);
  opacity:1;
  padding: 55px 0;
}
/******** 首页 *********/
/******** 关于我们 *********/
.advantage{
  width: 220px;
  height: 230px;
  padding: 0 25px;
  text-align: center;
  box-shadow: 0 5px 30px rgba(2,31,65,0.1);
  border-radius:4px;
}
.advantage img{
  margin: 30px auto 0;
}
.advantage p{
  padding-top: 20px;
}
/******** 关于我们 *********/
/******** 加入我们 *********/
.masonry {
  -moz-column-count: 2; /* Firefox */
  -webkit-column-count: 2; /* Safari 和 Chrome */
  column-count: 2;
  -moz-column-gap: 80px;
  -webkit-column-gap: 80px;
  grid-column-gap: 80px;
  column-gap: 80px;
}
.item {
  -moz-page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  -moz-column-break-inside: avoid;
       break-inside: avoid;
}
/******** 加入我们 *********/
/******** 成功案例 *********/
.Solution ul>li{
  overflow: hidden;
}
.Solution ul li img{
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.Solution ul li img:hover{
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
.Solution ul a:nth-child(3n+2){
  margin: 0 30px;
}
.Solution .cursor{
  cursor: pointer;
}

.realize{
  color: #333;
  border: 1px solid #ddd
}

.realize:hover{
  border: 1px solid #EA5413;
  color: #EA5413;
}
/******** 成功案例 *********/

.solutionDetail ul li img{
  width: 1200px;
}

@media screen and (max-width: 1223px) {
@charset "utf-8";

* {
  margin: 0;
  padding: 0;
}

fieldset, img ,span{
  border: 0;
  display: inline-block;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul, li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

abbr, acronym {
  border: 0;
}

a {
  color: #2c2c2c;
  cursor: pointer;
  text-decoration: none;
}

img {
  border: none;
}

body {
  width: 7.50rem;
  margin: 0 auto;
  font-family: "微软雅黑";
  background-color: #f5f5f5;
}

.overFlow{
  overflow: hidden;
}


/*** padding ***/
.p-20{
  padding: .20rem;
}

.pt-10{
  padding-top: .10rem;
}
.pt-25{
  padding-top: .25rem;
}
.pb-25{
  padding-bottom: .25rem;
}
.pt-40{
  padding-top: .40rem;
}
.pt-50{
  padding-top: .50rem;
}
.pt-20{
  padding-top: .20rem;
}
.pt-60{
  padding-top: .60rem;
}
.pt-70{
   padding-top: .70rem;
 }
.pt-90{
  padding-top: .90rem;
}
.pr-10{
  padding-right: .10rem;
}
.pr-30{
  padding-right: .30rem;
}
.pl-10{
  padding-left: .1rem;
}
.pl-20{
  padding-left: .20rem;
}
.pr-20{
  padding-right: .20rem;
}
.pl-30{
  padding-left: .30rem;
}
.pl-50{
  padding-left: .50rem;
}
.pl-25{
  padding-left: .25rem;
}
.l-105{
  line-height: 1.05rem;
}
.pt-5{
  padding-top: .05rem;
}
.pt-30{
  padding-top: .30rem;
}
.pt-38{
  padding-top: .38rem;
}
.pt-100{
  padding-top: 1.00rem;
}
.pb-10{
  padding-bottom: .10rem;
}
.pb-20{
  padding-bottom: .20rem;
}
.pb-30{
   padding-bottom: .30rem;
}
.pb-40{
  padding-bottom: .40rem;
}
.pb-50{
  padding-bottom: .50rem;
}
.pb-100{
  padding-bottom: 1.00rem;
}
.p-30{
  padding: .30rem;
}
.p-10{
  padding: .10rem;
}
/*** padding ***/
/*** margin ***/
.ml-20{
   margin-left: .20rem;
 }
.ml-30{
  margin-left: .30rem;
}
.marl-40{
  margin-left: .40rem;
}
.marl-10{
  margin-left: .10rem;
}
.mart-5{
  margin-top: .5rem;
}
.mart-30{
  margin-top: .30rem;
}
.mart-20{
  margin-top: .20rem;
}
.marR-20{
  margin-right: .20rem;
}
.mar-auto{
  margin: 0 auto;
}
.mar-b-10{
  margin-bottom: .10rem;
}
.mar-b-45{
  margin-bottom: .45rem;
}
/*** margin ***/
/*** border ***/
.bor-t{
  border-top: .01rem solid #e5e5e5;
}
.bor-t-ffa729{
  border-top: .01rem solid #ffa729;
}
.bor-r{
  border-right: .01rem solid #e5e5e5;
}
.bor-b-20{
  border-bottom: .20rem solid #f5f5f5;
}
.bor-t-20{
  border-top: .20rem solid #f5f5f5;
}
.bor-radius-10{
  border-radius: .10rem;
}
.bor-radius-20{
  border-radius: .20rem;
}
.bor-radius-5{
  border-radius: .05rem;
}
.bor-1rem{
  border: .01rem solid #a9a9a9;
}
.bor-L-1rem{
  border-left: .01rem solid #e5e5e5;
}
.bor-R-1rem{
  border-right: .01rem solid #e5e5e5;
}
.bor-B-1rem{
  border-bottom: .02rem solid #e5e5e5;
}
.bor-b-1px{
  border-bottom: .01rem solid #666666;
}
.bor-b-1px-e5{
  border-bottom: .01rem solid #e5e5e5;
}
/*** border ***/

/*** width ***/
.w0100{
  width: 100%;
}
.w025{
  width: 25%;
}
.w050{
  width: 50%;
}
.w03333{
  width: 33.3333333333%;
}

.w-148{
  width: 1.48rem;
}
.w-162{
  width: 1.62rem;
}
.w-240{
  width: 2.40rem;
}
.w-250{
  width: 2.50rem;
}
.w-400{
  width: 4.00rem;
}
.w-420{
  width: 4.20rem;
}
.w-470{
  width: 4.70rem;
}
.w-500{
  width: 5.00rem;
}
.w-540{
  width: 5.40rem;
}
.w-550{
  width: 5.50rem;
}
.w-410{
  width: 4.12rem;
}
.w-480{
  width: 4.80rem;
}
.w-570{
  width: 5.70rem;
}
.w-630{
  width: 6.30rem;
}
.w-690{
  width: 6.90rem;
}
.w-720{
  width: 7.20rem;
}
.w-730{
  width: 7.30rem;
}
.w-750{
  width: 7.50rem;
}
.w-38{
  width: .38rem;
}
.w-52{
  width: .52rem;
}
/*** width ***/

/*** height ***/

.hl-38{
  height: .38rem;
  line-height: .38rem;
}
.hl-100{
  height: 1.00rem;
  line-height: 1.00rem;
}
.h-160{
  height: 1.60rem;
}
.h-240{
  height: 2.40rem;
}
.h-300{
  height: 3.00rem;
}
.hl-60{
  height: .60rem;
  line-height: .60rem;
}
.h-52{
  height: .52rem;
}
.hl-52{
  height: .52rem;
  line-height: .52rem;
}
.hl-70{
  height: .70rem;
  line-height: .70rem;
}
.hl-80{
  height: .80rem;
  line-height: .80rem;
}
.height-100{
  height: 100%;
}
.hl-99{
  height: .99rem;
  line-height: .99rem;
}
.hl-98{
  height: .98rem;
  line-height: .98rem;
}
.hl-90{
  height: .90rem;
  line-height: .90rem;
}
.hl-82{
  height: .82rem;
  line-height: .82rem;
}
.hl-200{
  height: 2.00rem;
  line-height: 2.00rem;
}
.h-400{
  height: 4.00rem;
}
/*** height ***/

/*** font ***/
.f-24{
  font-size: .24rem;
}
.f-30{
  font-size: .30rem;
}
.f-28{
  font-size: .28rem;
}
.f-26{
  font-size: .26rem;
}
.f-32{
  font-size: .32rem;
}
.f-36{
  font-size: .36rem;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.text-decoration{
  text-decoration: line-through;
}
.t-indent-60 {
  text-indent: .60rem;
}
.f-bold{
  font-weight: bold;
}
.t-indent-20{
  text-indent: .20rem;
}
/*** font ***/
/*** color ***/
.c-ffa729{
  color: #ffa729;
}
.c-ff6c76{
  color: #ff6c76;
}
.c-ff668f{
  color: #ff668f;
}
.c-ff789c{
  color: #ff789c;
}
.c-f23c3b{
  color: #F23C3B;
}
.c-80bfff{
  color: #80bfff;
}
.c-757575{
  color: #757575;
}
.c-333{
  color: #333333;
}
.c-666{
  color: #666666;
}
.c-999{
  color: #999999;
}
.c-fff{
  color: #ffffff;
}
.c-fe8c25{
  color: #fe8c25;
}
.c-ea5413{
  color: #EA5413;
}
/*** color ***/
/*** background-color ***/
.bg-f5f5f5{
  background-color: #f5f5f5;
}
.bg-ffa729{
   background-color: #ffa729;
}
.bg-fff{
  background-color: #ffffff;
}

.RegBox .bg-c-ffa729{
  background-color: #ffa729;
  border: .02rem solid #ffa729;
  color: #ffffff;
}
.bg-ea5413{
  background-color: #EA5413;
}
.bg-ff789c{
  background-color: #ff789c;
}
.bg-999{
  background-color: #999;
}
/*** background-color ***/
/**border**/
.bor-fa729{
  border: .01rem solid #ffa729;
}
.bor-fa729-2{
  border: .02rem solid #ffa729;
}
.bor-b-2-EA5413{
  border-bottom: 2px solid #EA5413;;
}
.bor-b-2-fa729{
  border-bottom: .02rem solid #ffa729;;
}
.bor-t-dashed{
  border-top: .02rem dashed #e5e5e5;
}
/**border**/
/*** flex ***/
.space-between{
  display: flex;
  justify-content: space-between;
}
.space-around{
  display: flex;
  justify-content: space-around;
}
.flex-start{
  display: flex;
  justify-content: flex-start;
}
.flex-end{
  display: flex;
  justify-content: flex-end;
}
.flex{
  display: flex;
}
.flex-grow{
  flex-grow: 1;
}
.flex-wrap{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
/*** flex ***/
/*** position ***/
.pos-center{
  position: absolute;
  top: 3.00rem;
  left: .60rem;
}
.pos-center-100{
  position: absolute;
  top: 1.50rem;
  left: .60rem;
}
.pos-center-80{
  position: absolute;
  top: .80rem;
  left: .30rem;
}
.pos-center-cancel{
  position: absolute;
  top: 4.00rem;
  left: .95rem;
}
.pos-bottom{
  position: fixed;
  left: 0;
  bottom: 0;
}
.pos-relative{
  position: relative;
}
.pos-repeat{
  position: absolute;
  left: 0;
  top: 0;
}
.pos-fixed{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 888;
  width: 7.50rem;
  background-color: #ffffff;
}
/*** position ***/
/*** display ***/
.display-inline-block{
  display: inline-block;
}
/*** z-index ****/

.z-index{
  z-index: 109;
}
.z-index-99{
  z-index: 99;
}

}
@media screen and (max-width: 1223px) {
.menuH5 .menuList{
  height: 100%;
  background-color: #1A1B1C;
  width: 5.28rem;
  z-index: 9999;
}
.menuH5 .menuList li{
  width: 100%;
  text-align: left;
}
img{
  display: block;
}
/******** 首页 *********/

.tomove{
  width: 100%;
  overflow: hidden;
  position: relative;
}
.tomove-list{
  position: relative;
  height: auto;
}
.tomove-list li img{
  display: block;
}
.transition{
  -webkit-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s
}
.slippage{
  position: absolute;
  bottom: 0.4rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.developLiH5{
  width: 6.3rem;
  margin: 0.8rem auto 0;
}
.more1{
  border: 0.01rem solid #CECECE;
  width: 3.98rem;
  height: 0.8rem;
  background-color: #fff;
  color: #666;
}
.left{
   width: 1rem;
   height: 1rem;
   position: absolute;
   top: 2.5rem;
   left: 0.2rem;
 }
.right{
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 2.5rem;
  right: 0.2rem;
}


.Honors{
  width: 3.3rem;
  background-color: #fff;
  box-shadow: 0 0.05rem 0.3rem rgba(2,31,65,0.1);
}

.jobs{
  padding: 0.48rem 0 0.5rem;
  border-bottom: 0.01rem dotted #ddd;
}
.jobs:last-child{
  border-bottom: none
}
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

a{
  text-decoration: none;
  color: #333;
}
.beian a:hover {
    color: #EA5413!important;
}
