img{
  display: block;
}
.w-1200{
  width: 1200px;
}
/******** 首页 *********/
.pos-sticky{
  position: sticky;
  top: 80px;
}
.top-head{
  width: 100%;
}
.bor-t-3px{
  height: 77px;
  padding: 0 10px;
  line-height: 74px;
  display: inline-block;
  border-top: 3px solid #ffffff;
}
.bor-t-3px-ea5413{
  height: 77px;
  line-height: 74px;
  display: inline-block;
  border-top: 3px solid #ea5413;
}
.page-header{
  width: 1200px;
  margin: 0 auto;
}
.menuList li{
  width: 140px;
  text-align: center;
}
.tomove{
  width: 100%;
  overflow: hidden;
  position: relative;
}
.tomove-list{
  position: relative;
  height: auto;
}
.tomove-list li img{
  display: block;
}
.transition{
  transition: all 0.8s ease 0s
}
.slippage{
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.icon{
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
}
.icon button{
  width: 100px;
  height: 50px;
  border: none;
  opacity: 0.3;
  margin-top: 250px;
  background-color: rgba(0,0,0,0);
}
button{
  outline: none;
}
.icon button:hover{
  opacity: 1;
}
.icon_back button{
  padding-left: 50px;
}
.icon_next button{
  padding-right: 50px;
}
.icon_back{
  left: 0
}
.icon_next{
  right: 0
}
.title{
  width: 100%;
  text-align: center;
  position: absolute;
  top: 210px;
  animation: title 1s linear;
}
.dec{
  width: 100%;
  text-align: center;
  position: absolute;
  top: 290px;
  animation: dec 1s linear;
}
.dec p{
  width: 700px;
  margin: 0 auto;
}
@keyframes title {
  from {top: 600px;}
  to { top: 210px;}
}
@keyframes dec {
  from {top: 0;}
  to { top: 290px;}
}
.bigTitle{
  position: absolute;
  top: 50px;
  animation: bigTitle 1s linear;
}
@keyframes bigTitle {
  from {top: 170px;}
  to { top: 50px;}
}
.subTitle{
  position: absolute;
  top: 90px;
  animation: subTitle 1s linear;
}
@keyframes subTitle {
  from {top: 0;}
  to { top: 90px;}
}
.more{
  position: absolute;
  width: 80px;
  height: 30px;
  line-height: 30px;
  top: 120px;
  right: 0;
  animation: more 1s linear;
}
.more:hover{
  color: #EA5413;
  border: 1px solid #EA5413;
}

@keyframes more {
  from { right: -200px; }
  to { right: 0;}
}

.develop{
  width: 280px;
  height: 276px;
  padding: 0 40px;
  position: relative;
  box-shadow: 0 5px 30px rgba(2,31,65,0.1);
  border-radius:4px;
}
.develop img{
  position: absolute;
  top: 30px;
  left: 150px;
  animation: developImg 1s linear;
}
.develop p{
  width: 280px
}

@keyframes developImg {
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
}
.develop .p1{
  position: absolute;
  top: 120px;
  animation: developP1 1s linear;
}

@keyframes developP1 {
  from { top: 160px }
  to { top: 120px; }
}
.develop .p2{
  position: absolute;
  top: 170px;
  animation: developP2 1s linear;
}

@keyframes developP2 {
  from { top: 220px }
  to { top: 170px; }
}
.down_title{
  position: absolute;
  top: 140px;
}
.down_top_text{
  width: 100%;
  position: absolute;
  top: 40px;
}
.down_top_icon{
  width: 100%;
  position: absolute;
  bottom: 30px;
}
.downLi{
  width: 240px;
}
.downLi img{
  margin: 0 auto;
}
.about{
  margin-top: 115px;
  width: 148px;
  height: 36px;
  border: 1px solid #ddd;
  color: #333;
  font-size: 14px;
  background-color: rgba(0,0,0,0);
}
.about:hover{
  border: 1px solid #EA5413;
  color: #EA5413;
}

.posCut{
  width:1200px;
  height: 90px;
  background:rgba(255,255,255,1);
  box-shadow:0 5px 15px rgba(0,0,0,0.15);
  opacity:1;
  padding: 55px 0;
}
/******** 首页 *********/
/******** 关于我们 *********/
.advantage{
  width: 220px;
  height: 230px;
  padding: 0 25px;
  text-align: center;
  box-shadow: 0 5px 30px rgba(2,31,65,0.1);
  border-radius:4px;
}
.advantage img{
  margin: 30px auto 0;
}
.advantage p{
  padding-top: 20px;
}
/******** 关于我们 *********/
/******** 加入我们 *********/
.masonry {
  -moz-column-count: 2; /* Firefox */
  -webkit-column-count: 2; /* Safari 和 Chrome */
  column-count: 2;
  -moz-column-gap: 80px;
  -webkit-column-gap: 80px;
  column-gap: 80px;
}
.item {
  -moz-page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}
/******** 加入我们 *********/
/******** 成功案例 *********/
.Solution ul>li{
  overflow: hidden;
}
.Solution ul li img{
  transition: all 0.5s;
}
.Solution ul li img:hover{
  transform: scale(1.5);
}
.Solution ul a:nth-child(3n+2){
  margin: 0 30px;
}
.Solution .cursor{
  cursor: pointer;
}

.realize{
  color: #333;
  border: 1px solid #ddd
}

.realize:hover{
  border: 1px solid #EA5413;
  color: #EA5413;
}
/******** 成功案例 *********/

.solutionDetail ul li img{
  width: 1200px;
}
