.menuH5 .menuList{
  height: 100%;
  background-color: #1A1B1C;
  width: 5.28rem;
  z-index: 9999;
}
.menuH5 .menuList li{
  width: 100%;
  text-align: left;
}
img{
  display: block;
}
/******** 首页 *********/

.tomove{
  width: 100%;
  overflow: hidden;
  position: relative;
}
.tomove-list{
  position: relative;
  height: auto;
}
.tomove-list li img{
  display: block;
}
.transition{
  transition: all 0.8s ease 0s
}
.slippage{
  position: absolute;
  bottom: 0.4rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.developLiH5{
  width: 6.3rem;
  margin: 0.8rem auto 0;
}
.more1{
  border: 0.01rem solid #CECECE;
  width: 3.98rem;
  height: 0.8rem;
  background-color: #fff;
  color: #666;
}
.left{
   width: 1rem;
   height: 1rem;
   position: absolute;
   top: 2.5rem;
   left: 0.2rem;
 }
.right{
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 2.5rem;
  right: 0.2rem;
}


.Honors{
  width: 3.3rem;
  background-color: #fff;
  box-shadow: 0 0.05rem 0.3rem rgba(2,31,65,0.1);
}

.jobs{
  padding: 0.48rem 0 0.5rem;
  border-bottom: 0.01rem dotted #ddd;
}
.jobs:last-child{
  border-bottom: none
}