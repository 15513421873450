@charset "utf-8";

* {
  margin: 0;
  padding: 0;
}

fieldset, img ,span{
  border: 0;
  display: inline-block;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul, li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

abbr, acronym {
  border: 0;
}

a {
  color: #2c2c2c;
  cursor: pointer;
  text-decoration: none;
}

img {
  border: none;
}

body {
  width: 7.50rem;
  margin: 0 auto;
  font-family: "微软雅黑";
  background-color: #f5f5f5;
}

.overFlow{
  overflow: hidden;
}


/*** padding ***/
.p-20{
  padding: .20rem;
}

.pt-10{
  padding-top: .10rem;
}
.pt-25{
  padding-top: .25rem;
}
.pb-25{
  padding-bottom: .25rem;
}
.pt-40{
  padding-top: .40rem;
}
.pt-50{
  padding-top: .50rem;
}
.pt-20{
  padding-top: .20rem;
}
.pt-60{
  padding-top: .60rem;
}
.pt-70{
   padding-top: .70rem;
 }
.pt-90{
  padding-top: .90rem;
}
.pr-10{
  padding-right: .10rem;
}
.pr-30{
  padding-right: .30rem;
}
.pl-10{
  padding-left: .1rem;
}
.pl-20{
  padding-left: .20rem;
}
.pr-20{
  padding-right: .20rem;
}
.pl-30{
  padding-left: .30rem;
}
.pl-50{
  padding-left: .50rem;
}
.pl-25{
  padding-left: .25rem;
}
.l-105{
  line-height: 1.05rem;
}
.pt-5{
  padding-top: .05rem;
}
.pt-30{
  padding-top: .30rem;
}
.pt-38{
  padding-top: .38rem;
}
.pt-100{
  padding-top: 1.00rem;
}
.pb-10{
  padding-bottom: .10rem;
}
.pb-20{
  padding-bottom: .20rem;
}
.pb-30{
   padding-bottom: .30rem;
}
.pb-40{
  padding-bottom: .40rem;
}
.pb-50{
  padding-bottom: .50rem;
}
.pb-100{
  padding-bottom: 1.00rem;
}
.p-30{
  padding: .30rem;
}
.p-10{
  padding: .10rem;
}
/*** padding ***/
/*** margin ***/
.ml-20{
   margin-left: .20rem;
 }
.ml-30{
  margin-left: .30rem;
}
.marl-40{
  margin-left: .40rem;
}
.marl-10{
  margin-left: .10rem;
}
.mart-5{
  margin-top: .5rem;
}
.mart-30{
  margin-top: .30rem;
}
.mart-20{
  margin-top: .20rem;
}
.marR-20{
  margin-right: .20rem;
}
.mar-auto{
  margin: 0 auto;
}
.mar-b-10{
  margin-bottom: .10rem;
}
.mar-b-45{
  margin-bottom: .45rem;
}
/*** margin ***/
/*** border ***/
.bor-t{
  border-top: .01rem solid #e5e5e5;
}
.bor-t-ffa729{
  border-top: .01rem solid #ffa729;
}
.bor-r{
  border-right: .01rem solid #e5e5e5;
}
.bor-b-20{
  border-bottom: .20rem solid #f5f5f5;
}
.bor-t-20{
  border-top: .20rem solid #f5f5f5;
}
.bor-radius-10{
  border-radius: .10rem;
}
.bor-radius-20{
  border-radius: .20rem;
}
.bor-radius-5{
  border-radius: .05rem;
}
.bor-1rem{
  border: .01rem solid #a9a9a9;
}
.bor-L-1rem{
  border-left: .01rem solid #e5e5e5;
}
.bor-R-1rem{
  border-right: .01rem solid #e5e5e5;
}
.bor-B-1rem{
  border-bottom: .02rem solid #e5e5e5;
}
.bor-b-1px{
  border-bottom: .01rem solid #666666;
}
.bor-b-1px-e5{
  border-bottom: .01rem solid #e5e5e5;
}
/*** border ***/

/*** width ***/
.w0100{
  width: 100%;
}
.w025{
  width: 25%;
}
.w050{
  width: 50%;
}
.w03333{
  width: 33.3333333333%;
}

.w-148{
  width: 1.48rem;
}
.w-162{
  width: 1.62rem;
}
.w-240{
  width: 2.40rem;
}
.w-250{
  width: 2.50rem;
}
.w-400{
  width: 4.00rem;
}
.w-420{
  width: 4.20rem;
}
.w-470{
  width: 4.70rem;
}
.w-500{
  width: 5.00rem;
}
.w-540{
  width: 5.40rem;
}
.w-550{
  width: 5.50rem;
}
.w-410{
  width: 4.12rem;
}
.w-480{
  width: 4.80rem;
}
.w-570{
  width: 5.70rem;
}
.w-630{
  width: 6.30rem;
}
.w-690{
  width: 6.90rem;
}
.w-720{
  width: 7.20rem;
}
.w-730{
  width: 7.30rem;
}
.w-750{
  width: 7.50rem;
}
.w-38{
  width: .38rem;
}
.w-52{
  width: .52rem;
}
/*** width ***/

/*** height ***/

.hl-38{
  height: .38rem;
  line-height: .38rem;
}
.hl-100{
  height: 1.00rem;
  line-height: 1.00rem;
}
.h-160{
  height: 1.60rem;
}
.h-240{
  height: 2.40rem;
}
.h-300{
  height: 3.00rem;
}
.hl-60{
  height: .60rem;
  line-height: .60rem;
}
.h-52{
  height: .52rem;
}
.hl-52{
  height: .52rem;
  line-height: .52rem;
}
.hl-70{
  height: .70rem;
  line-height: .70rem;
}
.hl-80{
  height: .80rem;
  line-height: .80rem;
}
.height-100{
  height: 100%;
}
.hl-99{
  height: .99rem;
  line-height: .99rem;
}
.hl-98{
  height: .98rem;
  line-height: .98rem;
}
.hl-90{
  height: .90rem;
  line-height: .90rem;
}
.hl-82{
  height: .82rem;
  line-height: .82rem;
}
.hl-200{
  height: 2.00rem;
  line-height: 2.00rem;
}
.h-400{
  height: 4.00rem;
}
/*** height ***/

/*** font ***/
.f-24{
  font-size: .24rem;
}
.f-30{
  font-size: .30rem;
}
.f-28{
  font-size: .28rem;
}
.f-26{
  font-size: .26rem;
}
.f-32{
  font-size: .32rem;
}
.f-36{
  font-size: .36rem;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.text-decoration{
  text-decoration: line-through;
}
.t-indent-60 {
  text-indent: .60rem;
}
.f-bold{
  font-weight: bold;
}
.t-indent-20{
  text-indent: .20rem;
}
/*** font ***/
/*** color ***/
.c-ffa729{
  color: #ffa729;
}
.c-ff6c76{
  color: #ff6c76;
}
.c-ff668f{
  color: #ff668f;
}
.c-ff789c{
  color: #ff789c;
}
.c-f23c3b{
  color: #F23C3B;
}
.c-80bfff{
  color: #80bfff;
}
.c-757575{
  color: #757575;
}
.c-333{
  color: #333333;
}
.c-666{
  color: #666666;
}
.c-999{
  color: #999999;
}
.c-fff{
  color: #ffffff;
}
.c-fe8c25{
  color: #fe8c25;
}
.c-ea5413{
  color: #EA5413;
}
/*** color ***/
/*** background-color ***/
.bg-f5f5f5{
  background-color: #f5f5f5;
}
.bg-ffa729{
   background-color: #ffa729;
}
.bg-fff{
  background-color: #ffffff;
}

.RegBox .bg-c-ffa729{
  background-color: #ffa729;
  border: .02rem solid #ffa729;
  color: #ffffff;
}
.bg-ea5413{
  background-color: #EA5413;
}
.bg-ff789c{
  background-color: #ff789c;
}
.bg-999{
  background-color: #999;
}
/*** background-color ***/
/**border**/
.bor-fa729{
  border: .01rem solid #ffa729;
}
.bor-fa729-2{
  border: .02rem solid #ffa729;
}
.bor-b-2-EA5413{
  border-bottom: 2px solid #EA5413;;
}
.bor-b-2-fa729{
  border-bottom: .02rem solid #ffa729;;
}
.bor-t-dashed{
  border-top: .02rem dashed #e5e5e5;
}
/**border**/
/*** flex ***/
.space-between{
  display: flex;
  justify-content: space-between;
}
.space-around{
  display: flex;
  justify-content: space-around;
}
.flex-start{
  display: flex;
  justify-content: flex-start;
}
.flex-end{
  display: flex;
  justify-content: flex-end;
}
.flex{
  display: flex;
}
.flex-grow{
  flex-grow: 1;
}
.flex-wrap{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
/*** flex ***/
/*** position ***/
.pos-center{
  position: absolute;
  top: 3.00rem;
  left: .60rem;
}
.pos-center-100{
  position: absolute;
  top: 1.50rem;
  left: .60rem;
}
.pos-center-80{
  position: absolute;
  top: .80rem;
  left: .30rem;
}
.pos-center-cancel{
  position: absolute;
  top: 4.00rem;
  left: .95rem;
}
.pos-bottom{
  position: fixed;
  left: 0;
  bottom: 0;
}
.pos-relative{
  position: relative;
}
.pos-repeat{
  position: absolute;
  left: 0;
  top: 0;
}
.pos-fixed{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 888;
  width: 7.50rem;
  background-color: #ffffff;
}
/*** position ***/
/*** display ***/
.display-inline-block{
  display: inline-block;
}
/*** z-index ****/

.z-index{
  z-index: 109;
}
.z-index-99{
  z-index: 99;
}
