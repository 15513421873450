@charset "utf-8";

* {
  margin: 0;
  padding: 0;
}

fieldset, img ,span{
  border: 0;
  display: inline-block;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul, li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

abbr, acronym {
  border: 0;
}

a {
  cursor: pointer;
}

img {
  border: none;
}

body {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  font-family: "微软雅黑";
  background-color: #f5f5f5;
}


/*** padding ***/
.p-20{
  padding: 20px;
}

.pt-10{
  padding-top: 10px;
}
.pt-15{
  padding-top: 15px;
}
.pt-40{
  padding-top: 40px;
}
.pt-20{
   padding-top: 20px;
}
.pt-25{
  padding-top: 25px;
}
.pt-60{
  padding-top: 60px;
}
.pt-70{
   padding-top: 70px;
 }
.pt-90{
  padding-top: 90px;
}
.pr-10{
  padding-right: 10px;
}
.pr-30{
  padding-right: 30px;
}
.pl-10{
  padding-left: 10px;
}
.pl-15{
  padding-left: 15px;
}
.pl-20{
  padding-left: 20px;
}
.pr-15{
  padding-right: 15px;
}
.pr-20{
  padding-right: 20px;
}
.pl-30{
  padding-left: 30px;
}
.pl-25{
  padding-left: 25px;
}
.l-105{
  line-height: 105px;
}
.pt-5{
  padding-top: 5px;
}
.pt-30{
  padding-top: 30px;
}
.pt-38{
  padding-top: 38px;
}
.pt-50{
  padding-top: 50px;
}
.pt-100{
  padding-top: 100px;
}
.pb-5{
  padding-bottom: 5px;
}
.pb-10{
  padding-bottom: 10px;
}
.pb-20{
  padding-bottom: 20px;
}
.pb-30{
   padding-bottom: 30px;
}
.pb-40{
  padding-bottom: 40px;
}
.pb-50{
  padding-bottom: 50px;
}
.pb-100{
  padding-bottom: 100px;
}
.p-30{
  padding: 30px;
}
.p-10{
  padding: 10px;
}
/*** padding ***/
/*** margin ***/
.mar-20{
  margin: 20px;
}
.ml-18{
  margin-left: 18px;
}
.ml-20{
   margin-left: 20px;
 }
.ml-30{
  margin-left: 30px;
}
.ml-40{
  margin-left: 40px;
}
.ml-10{
  margin-left: 10px;
}
.mart-5{
  margin-top: 5px;
}
.mart-10{
  margin-top: 10px;
}
.mart-30{
  margin-top: 30px;
}
.mart-20{
  margin-top: 20px;
}
.mr-10{
  margin-right: 10px;
}
.mr-15{
  margin-right: 15px;
}
.mr-20{
  margin-right: 20px;
}
.mar-auto{
  margin: 0 auto;
}
.mar-b-10{
  margin-bottom: 10px;
}
.mar-b-15{
  margin-bottom: 15px;
}
.mar-b-20{
  margin-bottom: 20px;
}
.mar-b-45{
  margin-bottom: 45px;
}
/*** margin ***/
/*** border ***/
.bor-none{
  border: none
}
.bor-t{
  border-top: 1px solid #e5e5e5;
}
.bor-t-ffa729{
  border-top: 1px solid #ffa729;
}
.bor-r{
  border-right: 1px solid #e5e5e5;
}
.bor-b-20{
  border-bottom: 20px solid #f5f5f5;
}
.bor-t-20{
  border-top: 20px solid #f5f5f5;
}
.bor-radius-10{
  border-radius: 10px;
}
.bor-radius-20{
   border-radius: 20px;
 }
.bor-radius-30{
  border-radius: 30px;
}
.bor-radius-5{
  border-radius: 5px;
}
.bor-1px{
  border: 1px solid #a9a9a9;
}
.bor-1px-e7bcf1{
  border: 1px solid #E7ECF1;
}
.bor-l-1px{
  border-left: 1px solid #e5e5e5;
}
.bor-r-1px{
  border-right: 1px solid #e5e5e5;
}
.bor-b-none{
  border-bottom: none !important;
}
.bor-b-1px{
  border-bottom: 1px solid #ccc;
}
/*** border ***/

/*** width ***/
.w025{
  width: 25%;
}
.w03333{
  width: 33.33333333333%;
}
.w0100{
  width: 100%;
}
.w-60{
  width: 60px;
}
.w-70{
  width: 70px;
}
.w-100{
  width: 100px;
}
.w-120{
  width: 120px;
}
.w-148{
  width: 148px;
}
.w-162{
  width: 162px;
}
.w-180{
  width: 180px;
}
.w-200{
  width: 200px;
}
.w-240{
  width: 240px;
}
.w-250{
  width: 250px;
}
.w-300{
  width: 300px;
}
.w-400{
  width: 400px;
}
.w-420{
  width: 420px;
}
.w-470{
  width: 470px;
}
.w-500{
  width: 500px;
}
.w-540{
  width: 540px;
}
.w-550{
  width: 550px;
}
.w-410{
  width: 412px;
}
.w-480{
  width: 480px;
}
.w-570{
  width: 570px;
}
.w-630{
  width: 630px;
}
.w-690{
  width: 690px;
}
.w-720{
  width: 720px;
}
.w-730{
  width: 730px;
}
.w-750{
  width: 750px;
}
.w-38{
  width: 38px;
}
.w-22{
  width: 22px;
}
.w-34{
   width: 34px;
 }
.w-50{
  width: 50px;
}
.w-52{
  width: 52px;
}
/*** width ***/

/*** height ***/
.hl-32{
  height: 32px;
  line-height: 32px;
}
.hl-36{
  height: 36px;
  line-height: 36px;
}
.hl-38{
  height: 38px;
  line-height: 38px;
}
.hl-50{
  height: 50px;
  line-height: 50px;
}
.hl-80{
  height: 80px;
  line-height: 80px;
}
.hl-100{
  height: 100px;
  line-height: 100px;
}
.h-160{
  height: 160px;
}
.h-240{
  height: 240px;
}
.h-300{
  height: 300px;
}
.hl-60{
  height: 60px;
  line-height: 60px;
}
.h-22{
  height: 22px;
}
.h-34{
  height: 34px;
}
.h-50{
  height: 50px;
}
.h-52{
  height: 52px;
}
.hl-52{
  height: 52px;
  line-height: 52px;
}
.hl-70{
  height: 70px;
  line-height: 70px;
}
.height-100{
  height: 100%;
}
.hl-99{
  height: 99px;
  line-height: 99px;
}
.hl-98{
  height: 98px;
  line-height: 98px;
}
.hl-90{
  height: 90px;
  line-height: 90px;
}
.hl-82{
  height: 82px;
  line-height: 82px;
}
.hl-200{
  height: 200px;
  line-height: 200px;
}
.h-400{
  height: 400px;
}
/*** height ***/

/*** font ***/
.f-14{
  font-size: 14px;
}
.f-15{
  font-size: 15px;
}
.f-16{
  font-size: 16px;
}
.f-18{
  font-size: 18px;
}
.f-20{
  font-size: 20px;
}
.f-22{
  font-size: 22px;
}
.f-24{
  font-size: 24px;
}
.f-30{
  font-size: 30px;
}
.f-28{
  font-size: 28px;
}
.f-26{
  font-size: 26px;
}
.f-32{
  font-size: 32px;
}
.f-36{
  font-size: 36px;
}
.f-44{
  font-size: 44px;
}
.f-50{
  font-size: 50px;
}
.f-54{
  font-size: 54px;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.text-decoration{
  text-decoration: line-through;
}
.t-indent-60 {
  text-indent: 60px;
}
.f-bold{
  font-weight: bold;
}
.t-indent-20{
  text-indent: 20px;
}
/*** font ***/
/*** color ***/
.c-ffa729{
  color: #ffa729;
}
.c-ea5413{
  color: #EA5413;
}
.c-ff6c76{
  color: #ff6c76;
}
.c-ff668f{
  color: #ff668f;
}
.c-fe9e38{
  color: #fe9e38;
}
.c-3e6bb3{
  color: #3e6bb3;
}
.c-c0c0c0{
  color: #c0c0c0;
}
.c-ff789c{
  color: #ff789c;
}
.c-ff4c4c{
  color: #ff4c4c;
}
.c-6593ee{
  color: #6593EE;
}
.c-80bfff{
  color: #80bfff;
}
.c-757575{
  color: #757575;
}
.c-ccc{
  color: #ccc;
}
.c-333{
  color: #333333;
}
.c-3c3c3c{
  color: #3c3c3c;
}
.c-666{
  color: #666666;
}
.c-999{
  color: #999999;
}
.c-888{
  color: #888888;
}
.c-fff{
  color: #ffffff;
}
.c-fe8c25{
  color: #fe8c25;
}
/*** color ***/
/*** background-color ***/
.bg-f5f5f5{
  background-color: #f5f5f5;
}
.bg-666{
  background-color: #666666;
}
.bg-ffa729{
  background-color: #ffa729;
}
.bg-3856df{
  background-color: #3856df;
}
.bg-3e6bb3{
  background-color: #3E6BB3;
}
.bg-6593ee{
  background-color: #6593EE;
}
.bg-1fc397{
  background-color: #1fc397;
}
.bg-00c4c2{
  background-color: #00C4C2;
}
.bg-f1f2f7{
  background-color: #f1f2f7;
}
.bg-fe9e38{
  background-color: #fe9e38;
}
.bg-57c8f2{
  background-color: #57C8F2;
}
.bg-fa9533{
  background-color: #FA9533;
}
.bg-ff6c60{
  background-color: #FF6C60;
}
.bg-fff{
  background-color: #ffffff;
}
.bg-f8f8f8{
  background-color: #f8f8f8;
}
.bg-36404C{
  background-color: #36404C
}
.RegBox .bg-c-ffa729{
  background-color: #ffa729;
  border: 2px solid #ffa729;
  color: #ffffff;
}
.bg-ff789c{
  background-color: #ff789c;
}
/*** background-color ***/
/**border**/
.bor-fa729{
  border: 1px solid #ffa729;
}
.bor-fa729-2{
  border: 2px solid #ffa729;
}
.bor-1px-3e6bb3{
  border: 1px solid #3e6bb3;
}
.bor-b-2-EA5413{
  border-bottom: 2px solid #EA5413;;
}
.bor-t-dashed{
  border-top: 2px dashed #e5e5e5;
}
.bor-l-5px-00C4C2{
  border-left: 5px solid #00c4c2;
}
/**border**/
/*** flex ***/
.space-between{
  display: flex;
  justify-content: space-between;
}
.space-around{
  display: flex;
  justify-content: space-around;
}
.justify-center{
  display: flex;
  justify-content: center;
}
.flex-start{
  display: flex;
  justify-content: flex-start;
}
.flex-end{
  display: flex;
  justify-content: flex-end;
}
.flex{
  display: flex;
}
.flex-grow{
  flex-grow: 1;
}
.flex-wrap{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.display_none{
  display: none;
}
.display_inline_block{
  display: inline-block;
}

/*** flex ***/
/*** position ***/
.pos-center{
  position: absolute;
  top: 300px;
  left: 60px;
}
.pos-center-100{
  position: absolute;
  top: 150px;
  left: 60px;
}
.pos-center-80{
  position: absolute;
  top: 80px;
  left: 30px;
}
.pos-center-cancel{
  position: absolute;
  top: 400px;
  left: 95px;
}
.pos-bottom{
  position: fixed;
  left: 0;
  bottom: 0;
}
.pos-relative{
  position: relative;
}
.pos-repeat{
  position: absolute;
  left: 0;
  top: 0;
}
.pos-fixed{
  position: fixed;
  top: 0;
  left: 0;
}
/*** position ***/
/*** display ***/
.display-inline-block{
  display: inline-block;
}
/*** z-index ****/

.z-index{
  z-index: 109;
}
.z-index-99{
  z-index: 99;
}
